// 积分设置
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">积分商城</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >积分设置</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="addlist">
              <div>
                <div class="form-box">
                  <el-form
                    ref="baseform"
                    label-width="10rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                  >
                    <h3>
                      <span>基本信息</span>
                    </h3>
                    <el-form-item label="积分兑换：" prop="exchangeRate">
                      <div class="df">
                        <el-input
                          v-model="baseform.exchangeRate"
                          class="input_w"
                        ></el-input>
                        <span style="width: 80px; margin-left: 5px"
                          >积分 = 1元</span
                        >
                      </div>
                    </el-form-item>
                    <el-form-item label="积分规则：" prop="exchangeRemark">
                      <div >
                        <el-input
                          type="textarea"
                          v-model="baseform.exchangeRemark"
                          class="input_w"
                          resize="none"
                          :rows="6"
                        ></el-input>
                        <!-- <div ref="editor" class="editor" style="width: 100%" /> -->
                        <el-popover
                          placement="right"
                          trigger="hover"
                          v-model="visible"
                        >
                          <div class="flexdcc">
                            <img
                              src="../../../assets/integral/integral_3.png"
                              alt=""
                            />
                            <span style="padding: 5px 0"
                              >积分规则将在红框区域内展示</span
                            >
                          </div>
                          <span
                            slot="reference"
                            style="margin-left: 5px"
                            class="cur-a"
                            >查看示例</span
                          >
                        </el-popover>
                      </div>
                    </el-form-item>
                    <h3>
                      <span>提现设置</span>
                    </h3>
                    <el-form-item label="提现门槛：" prop="withdrawMin">
                      <div class="df">
                        <el-input
                          v-model="baseform.withdrawMin"
                          class="input_w"
                        ></el-input>
                        <span style="width: 80px; margin-left: 5px">元</span>
                      </div>
                      <p style="color: #999">达到门槛，才可进行提现</p>
                    </el-form-item>
                    <el-form-item
                      label="每日提现金额上限："
                      prop="withdrawDailyLimit"
                    >
                      <div class="df">
                        <el-input
                          v-model="baseform.withdrawDailyLimit"
                          class="input_w"
                        ></el-input>
                        <span style="width: 80px; margin-left: 5px">元</span>
                      </div>
                      <p style="color: #999">用户每天最多提现多少</p>
                    </el-form-item>
                    <el-form-item label="提现说明：" prop="withdrawRemark">
                      <div class="flexdc">
                        <el-input
                          type="textarea"
                          resize="none"
                          v-model="baseform.withdrawRemark"
                          class="input_w"
                          :rows="6"
                        ></el-input>
                        <!-- <div ref="editor1" class="editor" style="width: 100%" /> -->
                        <span style="color: #999"
                          >提现页面的规则说明
                          <el-popover
                            trigger="hover"
                            placement="right"
                            v-model="visible1"
                          >
                            <div class="flexdcc">
                              <img
                                src="../../../assets/integral/integral_4.png"
                                alt=""
                              />
                              <span style="padding: 5px 0"
                                >提现说明将在红框区域内展示</span
                              >
                            </div>
                            <span
                              slot="reference"
                              style="margin-left: 5px"
                              class="cur-a"
                              >查看示例</span
                            >
                          </el-popover></span
                        >
                      </div>
                    </el-form-item>
                    <h3>
                      <span>轮播图配置</span>
                    </h3>
                    <el-form-item label="轮播图：" prop="carouselPath">
                      <el-upload
                        :on-change="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload2"
                        :http-request="$requestMine"
                        :show-file-list="false"
                        class="img-el-upload"
                        action
                        accept="image/png, image/gif, image/jpg, image/jpeg"
                      >
                        <el-image
                          :src="
                            baseform.carouselPath ||
                            require('@/assets/develop.png')
                          "
                          fit="contain"
                          class="imgCenter"
                        ></el-image>
                      </el-upload>
                      <p style="color: #f46173">
                        最多上传1张，建议上传尺寸690*290px，分辨率72dpi
                        <el-popover
                          trigger="hover"
                          placement="right"
                          v-model="visible2"
                        >
                          <div class="flexdcc">
                            <img
                              src="../../../assets/integral/integral_1.png"
                              alt=""
                            />
                            <span style="padding: 5px 0"
                              >轮播图将在红框区域内展示</span
                            >
                          </div>
                          <span
                            slot="reference"
                            style="margin-left: 5px"
                            class="cur-a"
                            >查看示例</span
                          >
                        </el-popover>
                      </p>
                    </el-form-item>
                    <h3>
                      <span>引导图配置</span>
                    </h3>
                    <el-form-item label="引导图：" prop="guidPicPath">
                      <el-upload
                        :on-change="handleAvatarSuccess2"
                        :before-upload="beforeAvatarUpload2"
                        :http-request="$requestMine"
                        :show-file-list="false"
                        class="img-el-upload"
                        action
                        accept="image/png, image/gif, image/jpg, image/jpeg"
                      >
                        <el-image
                          :src="
                            baseform.guidPicPath ||
                            require('@/assets/develop.png')
                          "
                          fit="contain"
                          class="imgCenter"
                        ></el-image>
                      </el-upload>
                      <p style="color: #f46173">
                        <el-popover
                          trigger="hover"
                          placement="right"
                          v-model="visible3"
                        >
                          <div class="flexdcc">
                            <img
                              src="../../../assets/integral/integral_2.png"
                              alt=""
                              style="width: 100%"
                            />
                            <span style="padding: 5px 0"
                              >引导图将在红框区域内展示</span
                            >
                          </div>
                          <span
                            slot="reference"
                            style="margin-left: 5px"
                            class="cur-a"
                            >查看示例</span
                          >
                        </el-popover>
                      </p>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="btn-box">
                <el-button type="primary" class="bgc-bv" @click="doAddSave"
                  >确 定</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
let editor = {};
let editor1 = {};
export default {
  name: "PointsSet",
  components: {},
  data() {
    let checkInt = (rule, value, callback) => {
      value = value || "";
      let reg = /^\+?[1-9][0-9]*$/;
      let msg =
        rule.field == "exchangeRate"
          ? "积分兑换"
          : rule.field == "withdrawMin"
          ? "提现门槛"
          : "每日提现金额上限";
      if (value == "") {
        callback(new Error(msg + "不能为空"));
      } else if (!reg.test(value)) {
        callback(new Error(msg + "应为正整数"));
      } else {
        callback();
      }
    };
    let checkRemark = (rule, value, callback) => {
      // if (editor.txt.text().trim() == "") {
      //   callback(new Error("请输入积分规则"));
      // } else {
        callback();
      // }
    };
    let checkRemark1 = (rule, value, callback) => {
      // if (editor1.txt.text().trim() == "") {
      //   callback(new Error("请输入提现说明"));
      // } else {
        callback();
      // }
    };
    return {
      // 基本信息
      baseform: {
        visible: false,
        visible1: false,
        visible2: false,
        visible3: false,
        exchangeRate: "", // 兑换比例
        exchangeRemark: "", //
        withdrawMin: "", // 提现最低门槛
        withdrawDailyLimit: "", // 单日提现上限元
        withdrawRemark: "", // 提现说明
        carouselPath: "", // 轮播图地址
        guidPicPath: "", // 引导图地址
      },
      rules: {
        exchangeRate: [
          { required: true, trigger: "blur", validator: checkInt },
        ],
        exchangeRemark: [
          { required: true, trigger: "chang", message: "请输入积分规则" },
        ],
        withdrawMin: [{ required: true, trigger: "blur", validator: checkInt }],
        withdrawDailyLimit: [
          { required: true, trigger: "blur", validator: checkInt },
        ],
        withdrawRemark: [
          { required: true, trigger: "change",  message: "请输入提现说明" },
        ],
        carouselPath: [
          { required: true, trigger: "change", message: "请上传轮播图" },
        ],
        guidPicPath: [
          { required: true, trigger: "change", message: "请上传引导图" },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // this.editorInit();
    // this.editorInit1();
  },
  computed: {},
  methods: {
    init() {
      this.queryData();
    },
    // 新增保存
    doAddSave() {
      this.$refs.baseform.validate((valid) => {
        if (valid) {
          if (this.baseform.updateTime) {
            delete this.baseform.updateTime;
          }
          this.$post(
            "/bonuspoint/settings/v1/modify",
            {
              ...this.baseform,
              exchangeRemark: this.baseform.exchangeRemark.replace(/\n/g, "sunrealbr"),
              withdrawRemark: this.baseform.withdrawRemark.replace(/\n/g, "sunrealbr"),
            },
            3000,
            true,
            2
          ).then((res) => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "保存成功",
              });
            }
          });
        }
      });
    },
    // 编辑查询
    queryData() {
      this.$post("/bonuspoint/settings/v1/query", {}, 3000, false, 2).then(
        (res) => {
          if (res.status == "0") {
            this.baseform = {
              ...res.data,
              exchangeRemark: res.data.exchangeRemark.replace(/sunrealbr/g, "\n"),
              withdrawRemark: res.data.withdrawRemark.replace(/sunrealbr/g, "\n"),
            };
            // editor.txt.html(res.data.exchangeRemark);
            // editor1.txt.html(res.data.withdrawRemark);
          }
        }
      );
    },
    //
    handleAvatarSuccess(res, list) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.baseform.carouselPath = result.data.fileURL;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    //
    handleAvatarSuccess2(res, list) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.baseform.guidPicPath = result.data.fileURL;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;
      let _this = this;
      editor.config.customUploadImg = function (files, insert) {
        //对上传的图片进行处理，图片上传的方式
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          formData.append("folder ", "TEMP");
          formData.append(
            "fileType ",
            files[i].name.substring(files[i].name.lastIndexOf(".") + 1)
          );
          formData.append("isPublic ", true);
          formData.append("file", files[i]);
          _this.$Postformat("/sys/upload", formData).then((result) => {
            insert(result.data.fileURL);
          });
        }
      };
      editor.config.onchange = (html) => {
        this.baseform.exchangeRemark = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content;
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    // 富文本
    editorInit1() {
      editor1 = new E(this.$refs.editor1);
      editor1.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
      ];
      editor1.config.zIndex = 100;
      editor1.config.uploadImgShowBase64 = true;
      editor1.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor1.config.pasteFilterStyle = "text";
      editor1.config.pasteIgnoreImg = true;
      let _this = this;
      editor1.config.customUploadImg = function (files, insert) {
        //对上传的图片进行处理，图片上传的方式
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          formData.append("folder ", "TEMP");
          formData.append(
            "fileType ",
            files[i].name.substring(files[i].name.lastIndexOf(".") + 1)
          );
          formData.append("isPublic ", true);
          formData.append("file", files[i]);
          _this.$Postformat("/sys/upload", formData).then((result) => {
            insert(result.data.fileURL);
          });
        }
      };
      editor1.config.onchange = (html) => {
        this.baseform.withdrawRemark = html;
      };
      editor1.config.pasteTextHandle = function (content) {
        return content;
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor1.create();
      const eWidth = this.$refs.editor1.clientWidth;
      this.eWidth = eWidth;
    },
  },
};
</script>
<style lang="less">
.addlist {
  h3 {
    margin-left: 20px;
    display: flex;
    border-left: 4px solid #4574f9;
    span {
      margin-left: 20px;
    }
    margin-bottom: 30px;
  }
}
.form-box {
  // padding: 10px 20px;
  .form {
    // width: 80%;
    // padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
  .input_w {
    width: 80%;
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  // width: 80%;
  button {
    padding: 12px 30px;
  }
}
</style>
